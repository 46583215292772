<template>
  <div class="contacts-form__category">
    <div class="contacts-form__text">
      {{ label }}
      <template v-if="required">*</template>
    </div>
    <div class="contacts-form__stars">
      <Rating :required="required" v-model="value" />
    </div>
  </div>
</template>

<script>
import Rating from "@/components/rating";

export default {
  components: {
    Rating,
  },

  data() {
    return {
      value: null,
    };
  },

  watch: {
    value() {
      this.$emit("update:modelValue", this.value);
    },
  },

  props: {
    label: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
