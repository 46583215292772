<template>
  <div class="input-group" :class="classesAuto">
    <div class="input-group__wrapper">
      <div class="input-rating">
        <label
          class="input-rating__icon"
          :class="{ 'input-rating__icon--active': value === 5 }"
        >
          <input
            type="radio"
            :checked="value === 5"
            :disabled="disabled"
            @change="input(5)"
          />
          <svg class="icon icon-star-line icon-empty">
            <use xlink:href="#star"></use>
          </svg>
          <svg class="icon icon-star icon-fill">
            <use xlink:href="#star"></use>
          </svg>
        </label>

        <label
          class="input-rating__icon"
          :class="{ 'input-rating__icon--active': value === 4 }"
        >
          <input
            type="radio"
            :checked="value === 4"
            :disabled="disabled"
            @change="input(4)"
          />
          <svg class="icon icon-star-line icon-empty">
            <use xlink:href="#star"></use>
          </svg>
          <svg class="icon icon-star icon-fill">
            <use xlink:href="#star"></use>
          </svg>
        </label>

        <label
          class="input-rating__icon"
          :class="{ 'input-rating__icon--active': value === 3 }"
        >
          <input
            type="radio"
            :checked="value === 3"
            :disabled="disabled"
            @change="input(3)"
          />
          <svg class="icon icon-star-line icon-empty">
            <use xlink:href="#star"></use>
          </svg>
          <svg class="icon icon-star icon-fill">
            <use xlink:href="#star"></use>
          </svg>
        </label>

        <label
          class="input-rating__icon"
          :class="{ 'input-rating__icon--active': value === 2 }"
        >
          <input
            type="radio"
            :checked="value === 2"
            :disabled="disabled"
            @change="input(2)"
          />
          <svg class="icon icon-star-line icon-empty">
            <use xlink:href="#star"></use>
          </svg>
          <svg class="icon icon-star icon-fill">
            <use xlink:href="#star"></use>
          </svg>
        </label>

        <label
          class="input-rating__icon"
          :class="{ 'input-rating__icon--active': value === 1 }"
        >
          <input
            type="radio"
            :checked="value === 1"
            :disabled="disabled"
            @change="input(1)"
          />
          <svg class="icon icon-star-line icon-empty">
            <use xlink:href="#star"></use>
          </svg>
          <svg class="icon icon-star icon-fill">
            <use xlink:href="#star"></use>
          </svg>
        </label>
      </div>
    </div>
    <div class="input-group__error">{{ error }}</div>
  </div>
</template>

<script>
export default {
  props: {
    currentValue: {
      default: "",
    },
    error: {
      default: "",
    },
    required: {
      default: false,
    },
    disabled: {
      default: false,
    },
  },

  data() {
    return {
      value: this.currentValue,
    };
  },

  computed: {
    classesAuto() {
      return {
        "input-group--error": !!this.error,
      };
    },
  },

  methods: {
    input(val) {
      this.value = val;
      this.$emit("update:modelValue", val);
    },
  },
};
</script>
