<template>
  <form class="contacts-form" @submit.prevent="sendRequest" ref="form">
    <div class="personal-review__row">
      <div class="personal-review__input">
        <FormText :label="'Ваше имя'" :required="true" v-model="request.NAME"/>
      </div>
    </div>

    <div class="personal-review__row">
      <div class="personal-review__input">
        <FormText
          :label="'Телефон'"
          :pattern="'+# (###) ### ## ##'"
          :required="true"
          v-model="request.PHONE"
        />
      </div>
    </div>

    <div class="personal-review__row">
      <div class="personal-review__input">
        <FormText :label="'E-mail'" :required="false" v-model="request.EMAIL"/>
      </div>
    </div>

    <div class="personal-review__row">
      <div class="personal-review__input">
        <FormArea
          :label="'Комментарий'"
          :required="false"
          v-model="request.MESSAGE"
        />
      </div>
    </div>

    <div class="contacts-form__categories">
      <FormRating
        :label="'Ассортимент'"
        :required="false"
        v-model="request.ASSORTMENT"
      />

      <FormRating :label="'Цены'" :required="false" v-model="request.PRICES"/>

      <FormRating
        :label="'Работа курьеров'"
        :required="false"
        v-model="request.COURIER_WORK"
      />

      <FormRating
        :label="'Работа операторов'"
        :required="false"
        v-model="request.OPERATOR_WORK"
      />

      <FormRating
        :label="'Работа фотографов'"
        :required="false"
        v-model="request.PHOTOGRAPHERS_WORK"
      />

      <FormRating
        :label="'Работа комплектовщиков'"
        :required="false"
        v-model="request.PICKING_WORK"
      />
    </div>

    <div class="contacts-form__item">
      <div class="form-default__consent">
        <label class="checkbox">
          <input
            name="consent"
            type="checkbox"
            value="Y"
            checked
            v-model="isConsent"
          />
          <span class="checkbox__indicator">
            <svg class="icon icon-check ">
              <use xlink:href="#check"></use>
            </svg>
          </span>
          <span class="checkbox__description">
            Я даю свое согласие на
            <a href="/privacy-policy" target="_blank">
              обработку персональных данных
            </a>
          </span>
        </label>
      </div>
    </div>

    <button :disabled="!isConsent" class="personal-review__submit btn">
      Оставить отзыв
    </button>
  </form>
</template>

<script>
import FormText from "@/components/forms/form-text";
import FormArea from "@/components/forms/textarea";
import FormRating from "@/components/forms/form-rating";

export default {
  components: {
    FormText,
    FormArea,
    FormRating,
  },

  data() {
    return {
      request: {
        USER_ID: null,
        NAME: null,
        PHONE: null,
        EMAIL: null,
        MESSAGE: null,
        ASSORTMENT: null,
        PRICES: null,
        COURIER_WORK: null,
        OPERATOR_WORK: null,
        PHOTOGRAPHERS_WORK: null,
        PICKING_WORK: null,
      },
      isConsent: true,
    };
  },

  mounted() {
    let profile = this.$store.state.profile;

    this.request.USER_ID = profile;

    this.request.NAME = profile.name;
    this.request.PHONE = profile.personalPhone;
    this.request.EMAIL = profile.email;
  },

  methods: {
    sendRequest() {
      this.$store
        .dispatch("REVIEW_SEND_FORM", this.request)
        .then((response) => {
          this.$store.commit("SET_MESSAGE_MODAL_DATA", response.data);

          this.request = {
            USER_ID: null,
            NAME: null,
            PHONE: null,
            EMAIL: null,
            MESSAGE: null,
            ASSORTMENT: null,
            PRICES: null,
            COURIER_WORK: null,
            OPERATOR_WORK: null,
            PHOTOGRAPHERS_WORK: null,
            PICKING_WORK: null,
          };
        })
        .catch((error) => {
          this.$store.commit("SET_MESSAGE_MODAL_DATA", error.response.data);
        });
    },
  },
};
</script>
