<template>
  <div class="container">
    <div class="contacts__title title">Контакты</div>
    <div class="contacts-links">
      <a href="/" class="contacts-link">
        <span class="contacts-link__icon">
          <svg class="icon icon-сalling ">
            <use xlink:href="#сalling"></use>
          </svg>
        </span>
        <span class="contacts-link__text">{{ contacts.phone.main }}</span>
      </a>

      <a href="/" class="contacts-link">
        <span class="contacts-link__icon">
          <svg class="icon icon-message ">
            <use xlink:href="#message"></use>
          </svg>
        </span>
        <span class="contacts-link__text">{{ contacts.email }}</span>
      </a>

      <a href="/" class="contacts-link">
        <span class="contacts-link__icon">
          <svg class="icon icon-location ">
            <use xlink:href="#location"></use>
          </svg>
        </span>
        <span class="contacts-link__text">
          {{ contacts.address }}
        </span>
      </a>
    </div>
    <div class="contacts-map"><img src="/img/test_image.jpg" alt="" /></div>
    <div class="contacts-about">
      <div class="contacts-about__title title title--second">Palladi.ru</div>
      <div class="contacts-about__text text">
        Интернет-гипермаркет, которому доверяют тысячи постоянных покупателей.
        Мы стремимся сделать все, чтобы избавить Вас от необходимости ходить в
        магазины за рутинными покупками!
      </div>
    </div>

    <FormReview />

    <div class="contacts-socials">
      <div class="contacts-socials__title title title--second">
        Оставайтесь на связи
      </div>
      <div class="contacts-socials__wrapper">
        <a :href="contacts.vk" class="contacts-social">
          <img src="/img/icons/vk.svg" />
        </a>
        <a :href="contacts.facebook" class="contacts-social">
          <img src="/img/icons/fb.svg" />
        </a>
        <a :href="contacts.instagram" class="contacts-social">
          <img src="/img/icons/ig.svg" />
        </a>
        <a :href="contacts.ok" class="contacts-social">
          <img src="/img/icons/ok.svg" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import FormReview from "@/views/review/Form-review";

export default {
  components: {
    FormReview,
  },

  computed: {
    contacts() {
      return this.$store.state.contacts;
    },
  },
};
</script>
